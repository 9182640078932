import * as Actions from "../actions";

const initialState = {
    role: [], //guest
    profile: 'Super Administrator', //Read/ View Only   Super Administrator Reporting Access
    data: {
        displayName: "John Doe",
        photoURL: "assets/images/avatars/Velazquez.jpg",
        email: "johndoe@eamstech.com",
        shortcuts: ["calendar", "mail", "contacts", "todo"],
        info: {
            hourlyRate: "", //2000_MAINT
            maintenancePlantId: "",
            personCode: "FRA010",
            groupsDescription1: "Technical Support",
            userId: 130,
            canCloseWorkOrder: false,
            canCompleteWorkOrder: true,
            canConvertWorkRequest: true,
            canCreateWorkOrder: true,
            canCreateWorkrequest: true,
            canDeclineWorkRequest: false,
            canEditCompletedWorkOrder: false,
            canEditInReviewWorkOrder: true,
            canEditOpenWorkOrder: true,
            canSubmitForReview: true,
            canAccessCrews: true,
            canAccessAnalytics: true,
            viewAsset: true,
            editAsset: true,
            createAsset: true
        }
  }
};

const user = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_USER_DATA: {
      return {
        ...initialState,
        ...action.payload
      };
    }
    case Actions.REMOVE_USER_DATA: {
      return {
        ...initialState
      };
    }
    case Actions.USER_LOGGED_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default user;

/*const initialState = {
    role: [], //guest
    profile: 'Super Administrator', //Read/ View Only   Super Administrator
    data: {
        displayName: "John Doe",
        photoURL: "assets/images/avatars/Velazquez.jpg",
        email: "johndoe@eamstech.com",
        shortcuts: ["calendar", "mail", "contacts", "todo"],
        path: "/analytics/maintenance-analysis",
        info: {
            hourlyRate: "", //2000_MAINT
            maintenancePlantId: "",
            personCode: "FRA010",
            groupsDescription1: "Technical Support",
            userId: 130,
            canCloseWorkOrder: false,
            canCompleteWorkOrder: true,
            canConvertWorkRequest: true,
            canCreateWorkOrder: true,
            canCreateWorkrequest: true,
            canDeclineWorkRequest: false,
            canEditCompletedWorkOrder: false,
            canEditInReviewWorkOrder: true,
            canEditOpenWorkOrder: true,
            canSubmitForReview: true,
            canAccessCrews: true,
            canAccessAnalytics: true,
            canAccess: [
                {
                    id: 1,
                    permissions: {
                        canCloseWorkOrder: false,
                        canCompleteWorkOrder: true,
                        canConvertWorkRequest: true,
                        canCreateWorkOrder: true,
                        canCreateWorkrequest: true,
                        canDeclineWorkRequest: false,
                        canEditCompletedWorkOrder: false,
                        canEditInReviewWorkOrder: true,
                        canEditOpenWorkOrder: true,
                        canSubmitForReview: true,
                        canAccessCrews: true,
                        canAccessAnalytics: true,
                    }
                },
                id: 2,
                permissions: {
                    canDelete: false,
                    canCreate: true,
                    canEdit: true,
                    canSelect: true,
                }
            ]
        }
    }
};*/