import React from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";

function FracttalProdLogin(props) {
  const dispatch = useDispatch();

  function redirect() {
    window.location.href = "/redirect";
  }

  return (
    <div className="w-full">
      <Button
        className="w-full my-48"
        color="primary"
        variant="contained"
        onClick={redirect}
      >
        Log In
      </Button>
    </div>
  );
}

export default FracttalProdLogin;
